<template>
  <loading v-if="workflowLoading" :loading="workflowLoading" />
  <grid v-else container>
    <grid-column class="workflow" twelve>
      <div class="line" />
      <div class="line-start light-grey-background" />
      <grid v-for="action in actions" :key="`action-${action.id}`" centered>
        <grid-column sixteen>
          <flex justify="center">
            <action-card
              :id="`action-${action.id}`"
              :organization="organization"
              :moment="moment"
              :action="action"
              :relationships="relationships"
              :users="users"
              :groups="groups"
              :triggers="triggers"
              :aspects="aspects"
              :slack-conversations="slackConversations"
              :slack-integration="slackIntegration"
              @get-actions="(value) => $emit('get-actions', value)"
              @get-moment-status="() => $emit('get-moment-status')"
            />
          </flex>
        </grid-column>
        <grid-column>
          <flex justify="center">
            <dropdown
              class="add-step"
              name="Add Step"
              button
              icon="fas fa-plus"
              :button-variant="null"
              hide-selection
              hide-caret
              circular
              size="medium"
              floating
              :loading="creatingAction === action.id"
              @update="(value) => createAction(value, action)"
            >
              <dropdown-header> Add a Step </dropdown-header>
              <dropdown-item
                vertical
                :disabled="!slackIntegration"
                value="message"
              >
                <flex gap="0.5rem">
                  <i class="far fa-comment-alt" />
                  Send Message
                </flex>
              </dropdown-item>
              <dropdown-item vertical value="email">
                <flex gap="0.5rem">
                  <i class="far fa-envelope" />
                  Send Email
                </flex>
              </dropdown-item>
              <dropdown-item
                vertical
                :disabled="!calendarOwnerId"
                value="meeting"
              >
                <flex gap="0.5rem">
                  <i class="far fa-calendar" />
                  Create Meeting
                  <g-label size="mini" light-blue horizontal> Beta </g-label>
                </flex>
              </dropdown-item>
              <dropdown-item
                vertical
                :disabled="!slackIntegration"
                value="channel"
              >
                <flex gap="0.5rem">
                  <i class="fas fa-hashtag" />
                  Create Channel
                </flex>
              </dropdown-item>
              <dropdown-item
                :disabled="!slackIntegration || !relationships.length"
                vertical
                value="relationship"
              >
                <flex gap="0.5rem">
                  <i class="fas fa-people-arrows" />
                  Assign Relationship
                </flex>
              </dropdown-item>
            </dropdown>
          </flex>
        </grid-column>
      </grid>
    </grid-column>
    <grid-column four>
      <h3>
        Trigger
        <i
          v-if="moment.isActive"
          v-tippy
          class="fas fa-info-circle"
          content="You can't change these settings without turning off the moment"
        />
      </h3>
      <dropdown-v3
        name="Trigger"
        class="trigger"
        placeholder="Add Trigger"
        :value="moment.type"
        :disabled="moment.isActive"
        :loading="patchingMomentField === 'type'"
        :options="triggerTypes"
        label="name"
        reduced-field="value"
        @update="(value) => patchMoment('type', value)"
      />
      <date-picker
        v-if="moment.type === 'date'"
        dropdown
        :date="moment.triggerDate ? parseISO(moment.triggerDate) : null"
        :disabled="moment.isActive"
        :min-date="new Date()"
        variant="isoDate"
        @update="(value) => patchMoment('triggerDate', value)"
      />
      <dropdown-v3
        v-if="moment.type === 'aspect'"
        name="Information"
        placeholder="Select Custom Field"
        :value="moment.aspectId"
        reduced-field="id"
        :loading="patchingMomentField === 'aspectId'"
        :disabled="moment.isActive"
        :options="triggers"
        label="name"
        searchable
        @update="(value) => patchMoment('aspectId', value)"
      />

      <h3>Enrollees</h3>
      <list selection class="enroll-user-list">
        <list-item
          v-for="edge in userEnrollees"
          :key="`user-enrollees-${edge.id}`"
          :to="{ name: 'profile', params: { id: edge.user.id } }"
        >
          <enrollee
            :id="edge.id"
            :moment="moment"
            type="user"
            :avatar="edge.user.avatar || defaultUserAvatar"
            :name="edge.user.fullName"
            @get-enrollable-users="getEnrollableUsers"
            @get-user-enrollees="getUserEnrollees"
            @get-moment-status="() => $emit('get-moment-status')"
          />
        </list-item>
      </list>
      <list selection>
        <list-item
          v-for="edge in groupEnrollees"
          :key="`group-enrollees-${edge.id}`"
          :to="{ name: 'group', params: { id: edge.group.id } }"
        >
          <enrollee
            :id="edge.id"
            :moment="moment"
            type="group"
            :avatar="
              edge.group.isSmartGroup ? smartGroupAvatar : defaultGroupAvatar
            "
            :name="edge.group.name"
            @get-enrollable-groups="getEnrollableGroups"
            @get-group-enrollees="getGroupEnrollees"
          />
        </list-item>
      </list>

      <dropdown-v3
        name="Enroll People"
        class="enroll-btn"
        placeholder="+ Add Person"
        hide-caret
        searchable
        reduced-field="id"
        :options="enrollableUsers"
        label="fullName"
        :loading="enrollingUser"
        @update="enrollUser"
        @search="getEnrollableUsers"
      />
      <dropdown-v3
        name="Enroll Groups"
        class="enroll-btn"
        placeholder="+ Add Group"
        hide-caret
        reduced-field="id"
        :options="enrollableGroups"
        label="name"
        searchable
        :loading="enrollingGroup"
        @update="enrollGroup"
        @search="getEnrollableGroups"
      />
    </grid-column>
  </grid>
</template>

<script>
import { parseISO } from 'date-fns'
import { api } from '@/api'
import { toast } from '@/toasts'

import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import dropdownHeader from '@/components/v2/dropdown/header.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import flex from '@/components/v2/flex.vue'
import datePicker from '@/components/v2/date_picker.vue'
import loading from '@/components/v2/loading.vue'
import actionCard from '@/components/v2/action_card/action_card.vue'
import dropdownV3 from '@/components/v3/dropdown.vue'
import gLabel from '@/components/v2/label.vue'

import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'
import defaultGroupAvatar from '@/assets/img/group_avatar_small.png'
import smartGroupAvatar from '@/assets/img/smart_group_avatar_small.png'

import enrollee from '@/views/v2/moment/enrollee.vue'

import dateMixin from '@/mixins/v2/dateMixin'

const DEFAULT_CHANNEL_NAME = `
gather-<span class="message-content"><span class="mention" contenteditable="false">@Enrollee Full Name</span></span>
`
const DEFAULT_ACTION_BODY = '<p>Click to personalize the message</p>'

export default {
  components: {
    grid,
    gridColumn,
    dropdown,
    dropdownItem,
    dropdownHeader,
    list,
    listItem,
    flex,
    datePicker,
    loading,
    enrollee,
    actionCard,
    dropdownV3,
    gLabel,
  },
  mixins: [dateMixin],
  props: {
    moment: {
      type: Object,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      parseISO,
      defaultUserAvatar,
      defaultGroupAvatar,
      smartGroupAvatar,
      workflowLoading: false,
      enrollingUser: false,
      enrollingGroup: false,
      patchingMomentField: null,
      creatingAction: null,
      personalizationTokens: {},
      userEnrollees: [],
      groupEnrollees: [],
      enrollableUsers: [],
      users: [],
      groups: [],
      enrollableGroups: [],
      aspects: [],
      relationships: [],
      slackConversations: [],
      slackIntegration: null,
      triggers: [],
      organization: null,
      calendarOwnerId: null,
      triggerTypes: [
        {
          name: 'Date',
          value: 'date',
        },
        {
          name: 'Start Date',
          value: 'start_date',
        },
        {
          name: 'End Date',
          value: 'end_date',
        },
        {
          name: 'Birthday',
          value: 'birthday',
        },
        {
          name: 'Work Anniversary',
          value: 'work_anniversary',
        },
        {
          name: 'Custom Field',
          value: 'aspect',
        },
      ],
      nullAction: {
        momentId: this.moment.id,
        organizationId: this.moment.organizationId,
        relativeDate: { days: 0 },
        relativeTime: { hour: 12, minute: 0 },
        actionType: null,
        body: null,
        archiveDate: null,
        archiveTime: null,
        relationshipId: null,
        replyToUserId: null,
        replyToRelationshipId: null,
        replyToGroupId: null,
        subject: null,
        channelName: null,
        meetingTitle: null,
        relativeMeetingDate: null,
        relativeMeetingStartTime: null,
        relativeMeetingEndTime: null,
        meetingLocation: null,
      },
    }
  },
  watch: {
    $route() {
      if (this.$route.hash === '#workflow') {
        this.getWorkflow()
      }
    },
  },
  mounted() {
    if (this.$route.hash === '#workflow') {
      this.getWorkflow()
    }
  },
  methods: {
    async getWorkflow() {
      this.workflowLoading = true
      await this.getUserEnrollees()
      await this.getGroupEnrollees()
      await this.getEnrollableUsers()
      await this.getUsers()
      await this.getEnrollableGroups()
      await this.getGroups()
      await this.getTriggers()
      await this.getAspects()
      await this.getRelationships()
      await this.getSlackConversations()
      await this.getSlackIntegration()
      await this.getOrganization()
      this.workflowLoading = false
    },
    async getUserEnrollees() {
      try {
        this.userEnrollees = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/individuals`,
            {
              userStatus: ['active', 'onboarding'],
              sort: 'preferredFirstName,lastName,id',
            }
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getGroupEnrollees() {
      try {
        this.groupEnrollees = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/groups`,
            {
              sort: 'isSmartGroup-desc,name,id',
            }
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getUsers() {
      let response
      this.users = []
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              status: ['active', 'onboarding'],
              sort: 'preferredFirstName,lastName,id',
            }
          )
          this.users = this.users.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getEnrollableUsers(search) {
      try {
        this.enrollableUsers = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/users`, {
            excludeMoment: this.moment.id,
            ...(search ? { search } : {}),
            status: ['active', 'onboarding'],
            sort: 'preferredFirstName,lastName,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getEnrollableGroups(search) {
      try {
        this.enrollableGroups = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`, {
            excludeMoment: this.moment.id,
            ...(search ? { search } : {}),
            sort: 'isSmartGroup-desc,name,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getGroups() {
      let response
      this.groups = []
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              sort: 'isSmartGroup-desc,name,id',
            }
          )
          this.groups = this.groups.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getTriggers() {
      // triggers are date type aspects such as birthday or start date
      let response
      this.triggers = []
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              type: 'date',
              isEditable: 1,
            }
          )
          this.triggers = this.triggers.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getAspects() {
      let response
      this.aspects = []
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              isEditable: 1,
            }
          )
          this.aspects = this.aspects.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getRelationships() {
      let response
      this.relationships = []
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              sort: 'name,id',
            }
          )
          this.relationships = this.relationships.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getSlackConversations() {
      let response
      this.slackConversations = []
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/slack-conversations`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              sort: 'displayName,id',
            }
          )

          this.slackConversations = this.slackConversations.concat(
            response.data
          )
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getSlackIntegration() {
      try {
        this.slackIntegration = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}/slack-integration`
          )
        ).data
      } catch (error) {
        if (error.code !== 404) {
          toast.error(error)
        }
      }
    },
    async getOrganization() {
      try {
        this.organization = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}`
          )
        ).data
        this.calendarOwnerId = this.organization.calendarOwnerId
      } catch (error) {
        toast.error(error)
      }
    },
    async patchMoment(field, value) {
      try {
        this.patchingMomentField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingMomentField = null
      }

      this.$emit('get-moment')
    },
    async enrollUser(userId) {
      try {
        this.enrollingUser = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/individuals`,
          {
            userId: parseInt(userId, 10),
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.enrollingUser = false
      }
      await this.getUserEnrollees()
      await this.getEnrollableUsers()

      this.$emit('get-moment-status')
      await this.runFastForwardCheck()
    },
    async enrollGroup(groupId) {
      try {
        this.enrollingGroup = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/groups`,
          {
            groupId: parseInt(groupId, 10),
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.enrollingGroup = false
      }
      await this.getGroupEnrollees()
      await this.getEnrollableGroups()

      this.$emit('get-moment-status')
      await this.runFastForwardCheck()
    },
    async runFastForwardCheck() {
      let dryRunResult
      let dryRunError = false
      try {
        dryRunResult = (
          await api.post(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/scheduler/schedule`,
            {
              dryRun: true,
              momentId: this.moment.id,
            }
          )
        ).data
      } catch (error) {
        dryRunError = true
      }

      if (this.moment.isActive) {
        if (dryRunError) {
          toast.info('Some messages may be fast-forwarded for new enrollees')
        }

        if (dryRunResult && dryRunResult.willFastForward.length) {
          toast.info(
            `${dryRunResult.willFastForward.length} messages will fast forward for new enrollees`
          )
        }
      }
    },
    setToken(action, value) {
      this.personalizationTokens = {
        ...this.personalizationTokens,
        [this.personalizationTokens[action.id]]: value,
      }
    },
    async createAction(type, previousAction) {
      if (type === 'meeting') {
        this.createMeetingAction(previousAction)
      } else {
        let action
        try {
          this.creatingAction = previousAction.id
          action = (
            await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/actions`, {
              ...this.nullAction,
              ...{
                momentId: this.moment.id,
                organizationId: this.moment.organizationId,
                relativeDate: previousAction.relativeDate || { days: 0 },
                relativeTime: previousAction.relativeTime || {
                  hour: 12,
                  minute: 0,
                },
                actionType: type,
                body: DEFAULT_ACTION_BODY,
                channelName: type === 'channel' ? DEFAULT_CHANNEL_NAME : null,
              },
            })
          ).data
          await api.post(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${action.id}/recipients`,
            { model: 'enrollee' }
          )
        } catch (error) {
          toast.error(error)
        } finally {
          this.creatingAction = null
        }
        this.$emit('get-actions', { goToAction: action?.id })
      }
    },
    async createMeetingAction(previousAction) {
      let action
      try {
        this.creatingAction = previousAction.id
        action = (
          await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/actions`, {
            ...this.nullAction,
            ...{
              relativeDate: previousAction.relativeDate || { days: 0 },
              relativeTime: previousAction.relativeTime || {
                hour: 12,
                minute: 0,
              },
              actionType: 'meeting',
              body: 'Click to personalize the description',
              meetingTitle: `${this.moment.name} Meeting`,
              relativeMeetingDate: previousAction.relativeDate || { days: 0 },
              relativeMeetingStartTime: previousAction.relativeTime || {
                hour: 12,
                minute: 0,
              },
              relativeMeetingEndTime: previousAction.relativeTime
                ? {
                    hour: previousAction.relativeTime.hour + 1,
                    minute: previousAction.relativeTime.minute,
                  }
                : { hour: 13, minute: 0 },
            },
          })
        ).data
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${action.id}/recipients`,
          { model: 'enrollee' }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.creatingAction = null
      }
      this.$emit('get-actions', { goToAction: action?.id })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';
@import '~@/assets/less/text.less';

.workflow {
  padding-bottom: 25% !important;
  background-color: @light-grey;
}

.enroll-user-list {
  margin-bottom: 0;
}

.enroll-btn {
  margin-bottom: 0.5em;
}

.trigger {
  margin-bottom: 0.5em;
}

.line::after {
  position: absolute;
  top: 1em;
  bottom: 0;
  left: calc(50% - 2px);
  content: '';
  border-left: @workflow-timeline-border;
}

.line-start {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  margin: 0 auto 1em;
  border: @workflow-timeline-border;
  border-radius: 100%;
}

.action {
  margin: 1.5em 0;
}

.add-step {
  margin-left: 3px;
  border: @standard-border;
}
</style>
